<template>
  <div id="app">
    <div class="background-image">
      <img src="/bg.png" alt="Background Image" />
    </div>
 
    <div class="content">
      <p class="text">There is a shark~ <br>
                       There is a bee~<br> 
                          Uggh~<br>
                       $Sharkbee!<br> 
                 You are little $Sharkbee
      </p>
      <div class="additional-text" @click="copyText">
  <span>CA:</span><span>8pMcn6JZnA7ichg6AaNojqchLFVGSpEDAJkBohdMpump</span>
</div>
      <button class="button" onclick="window.location.href='https://www.pump.fun/8pMcn6JZnA7ichg6AaNojqchLFVGSpEDAJkBohdMpump'"><span class="buy-text">BUY</span></button>
    </div>
    
    <div class="social-icons">
      <a href="https://x.com/sharkbee_sol" target="_blank">
        <img src="/icon_tw@3x.png" alt="Twitter" />
      </a>
      <a href="https://t.me/sharkbee_sol" target="_blank">
        <img src="/icon_telg@3x.png" alt="Telegram" />
      </a>
    </div>

    <div class="gif-container">
      <img src="/ss.gif" alt="GIF Image" class="ss-gif" />
    </div>
  
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    copyText() {
      const textToCopy = '8pMcn6JZnA7ichg6AaNojqchLFVGSpEDAJkBohdMpump'; // 获取需要复制的文本
      navigator.clipboard.writeText(textToCopy) // 使用 Clipboard API 复制文本
        .then(() => {
          console.log('Text copied successfully!');
        })
        .catch(err => {
          console.error('Error copying text: ', err);
        });
    }
  }
}
</script>

<style>
/* 导入字体 */
@font-face {
  font-family: 'ziji';
  src: url('@/assets/fonts/ziji.ttf') format('truetype');
}

/* 全局设置字体和基本样式 */
html, body {
  margin: 0;
  height: 100%;
  font-family: 'ziji';
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图像铺满整个容器 */
}

.content {
  z-index: 2;
  position: absolute;
  top:39%; /* 向下移动 20% */
  left: 22%;
  transform: translate(-50%, 0);
  text-align: center; /* 居中对齐 */
  color: black;
}

.text {
  font-size: 2.4em;
  max-width: 650px;
  overflow-wrap: break-word;
  margin-top: 18px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.additional-text {
  left: 5%; /* 向左移动 5% */
  margin-top: 7px;
  font-size: 1.8em;
  color: black;
  cursor: pointer; /* 添加指针样式 */
  padding: 15px 40px; /* 添加内边距 */
  background-color: #659EAD; /* 背景色 */
  border-radius: 100px; /* 圆角 */
}



.additional-text span {
  text-decoration: none; /* 去除默认下划线 */
}

.additional-text:hover span:last-child {
  text-decoration: underline; /* 鼠标悬停时对最后一个 span 元素添加下划线 */
}

.additional-text {
  margin-top: -9px; /* 向上移动 additional-text */
}
.button {
  margin-top: 10px;
  padding:0px 50px;
  font-size: 5em;
  color: black;
  font-family: 'ziji', sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s, border-color 0.3s;
}

.button:hover {
  transform: scale(1.1);
  color: #FCF57F;
}


.social-icons {
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  z-index: 1;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color:  #AD4F4F;;
  border: 2px solid black; /* 添加黑色描边 */
  border-radius: 20%;
  margin-left: 50px;
  margin-top: -5px;
  transition: background-color 0.3s, transform 0.3s;
}

.social-icons a:first-child {
  margin-left: 0;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.social-icons a:hover {
  background-color: rgba(185, 206, 230, 0.8);
  transform: scale(1.1);
}

.gif-container {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
 
}

.ss-gif {
  max-width: 100%;
  height: auto;
  display: block;
  
}


</style>
